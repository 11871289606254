export const apiRoutes = {
  open: {
    warehouseList: 'api/open/warehouses',
  },
  dashboard: {
    key: 'dashboard/settings/{key}',
    profile: 'dashboard/settings/profile',
    singleProfile: 'dashboard/settings/profile/edit/{id}',
    logOut: 'dashboard/logout',
    logIn: 'login',
    changePassword: 'password/reset',
    verifyToken: 'password/reset/verify/token',
    refreshToken: 'refresh',
  },
  collecting: {
    root: 'api/collecting',
    orderId: 'api/activity/collecting/order/{id}',
    items: {
      getItemsList: 'api/collecting/{sessionId}/items',
      collect: 'api/collecting/{sessionId}/items/collect',
    },
    sessions: {
      getSessions: 'api/collecting/sessions/ongoing',
      joinToSession: 'api/collecting/sessions/{id}/join',
      cancelJoinToSession: 'api/collecting/sessions/{id}/request/cancel',
      approveJoinToSession: 'api/collecting/sessions/{sessionId}/requests/{employeeId}/accept',
      rejectJoinToSession: 'api/collecting/sessions/{sessionId}/requests/{employeeId}/reject',
      pause: 'api/collecting/sessions/{sessionId}/pause',
      cancel: 'api/collecting/sessions/{sessionId}/cancel',
    },
    leave: 'api/collecting/sessions/{sessionId}/leave',
  },
  packing: {
    root: 'api/packing',
    ongoing: 'api/packing/ongoing',
    getPackedItems: 'api/packing/{orderId}/items',
    deleteOrder: 'api/packing/{orderId}',
    pack: 'api/packing/pack',
    reset: 'api/packing/{orderId}',
    postWrongExpiryDate: 'api/packing/{orderId}/wrong-series-detected',
    finish: 'api/packing/{orderId}/finish',
    specialPacking: {
      itemsList: 'api/packing-to-package/order/{id}',
      addProductToBox: 'api/packing-to-package/pack',
      removeProductFromBox: 'api/packing-to-package/remove',
      removeBox: 'api/courier/package-order/{id}',
      toggleStatus: 'api/sales/order/{id}/detailed-box-packing',
      printOrder: 'api/packing-to-package/export/{id}',
    },
  },
  inventory: {
    error: 'api/inventory/errors',
    check: 'api/location/check',
    delivery: {
      root: 'api/delivery',
      idParam: 'api/delivery/{id}',
      deliveryId: 'api/delivery/{id}',
      export: 'api/delivery/export',
      pdfCsv: 'api/delivery/{id}/export',
      csvSample: 'api/inventory/delivery/csv/sample',
      employee: 'api/delivery/{deliveryId}/employees',
      expectedItems: 'api/delivery/{deliveryId}/expected-items',
      locationId: 'api/location/{locationId}/inventory',
      document: {
        root: 'api/delivery/{deliveryId}/documents',
        delete: 'api/delivery/{deliveryId}/documents/{documentId}',
        download: 'api/delivery/{deliveryId}/documents/{documentId}/contents',
      },
    },
    deliveryExpectedItem: {
      root: 'api/inventory/deliveryExpectedItem',
      import: 'api/delivery/{deliveryId}/expected-items/import',
      idParam: 'api/inventory/deliveryExpectedItem/{id}',
      parseCsv: 'api/inventory/deliveryExpectedItem/parseItemCsv',
      expectedItem: 'api/delivery/{deliveryId}/expected-items',
      expectedItemId: 'api/delivery/{deliveryId}/expected-items/{expectedItemId}',
      addDeliveryItem: 'api/delivery/{deliveryId}/delivered-items',
      editExpectedItem: 'api/delivery/{deliveryId}/delivered-items/{itemId}',
    },
    deliveryAcceptance: {
      idParam: 'api/delivery/{deliveryId}/approve',
    },
    replenishment: {
      root: 'api/inventory/replenishment',
      status: {
        unassigned: 'api/replenishments/unassigned',
        new: 'api/replenishments/new',
        assigned: 'api/replenishments/assigned',
        done: 'api/replenishments/done',
        reserved: 'api/replenishments/reserved',
      },
      action: {
        take: 'api/replenishment/{replenishmentId}/take',
        replenish: 'api/replenishment/{replenishmentId}/replenish',
      },
      idParam: 'api/inventory/replenishment/{id}',
      take: 'api/inventory/replenishment/take/{id}',
      reserveId: 'api/inventory/replenishment/reserve/{id}',
      activeReservedByEmployeeStorageLocation:
        'api/inventory/replenishment/activeReservedByEmployeeByStorageLocation/{barcode}',
      scan: 'api/inventory/replenishment/scan',
      getTakenForPickingLocation: 'api/inventory/replenishment/getTakenForPickingLocation',
      put: 'api/inventory/replenishment/put',
      replatedAutoReplenishment: 'api/inventory/replenishment/relatedAutoReplenishment/{id}',
      replenishmentLevel: 'api/replenishment-level',
      singleReplenishmentLevel: 'api/replenishment-level/{id}',
    },
    checkRequest: {
      root: 'api/inventory-check/request',
      popup: 'api/inventory-check/{id}/popup',
      stocktaking: 'api/inventory-check/{id}/save-stocktaking-result',
      resolve: 'api/inventory-check/request/{id}/resolve',
    },
  },
  pim: {
    product: {
      root: 'api/pim/product',
      idParam: 'api/pim/product/{id}',
      ean: 'api/pim/product/ean/{ean}',
      update: 'api/location/check',
      series: 'api/pim/product/{productId}/series',
      shortDate: 'api/pim/product/shortDateLevel',
      singleShortDate: 'api/pim/product/shortDateLevel/{id}',
      inventory: 'api/product/{productId}/inventory',
      availableStocks: 'api/product/{productId}/available-stocks',
      damagedProducts: 'api/pim/product/damaged',
      addSeries: 'api/pim/product/series',
      warehouses: 'api/pim/product/{productId}/warehouses',
      checkSeries: 'api/pim/product/{productId}/series',
    },
    defaultLocation: {
      get: 'api/pim/product/{productId}/default-locations',
      put: 'api/pim/product/{productId}/default-locations/{locationId}',
      delete: 'api/pim/product/{productId}/default-locations/{locationId}',
    },
    manufacturer: {
      root: 'api/pim/manufacturer',
    },
    logs: {
      logTypes: 'api/monitoring/transfer-logs/types',
      logModules: 'api/monitoring/transfer-logs/modules',
      logActions: 'api/monitoring/transfer-logs/module/{module}/actions',
    },
    massaction: {
      replenishmentLevel: 'api/pim/product/massaction/replenishmentLvl/ids',
      replenishmentLevelByFilters: 'api/pim/product/massaction/replenishmentLvl/filters',
      shortDateLevel: 'api/pim/product/massaction/shortDateLvl/ids',
      shortDateLevelByFilters: 'api/pim/product/massaction/shortDateLvl/filters',
    },
    shortDateLevel: 'api/pim/product/shortDateLevel',
    singleShortDateLevel: 'api/pim/product/shortDateLevel/{id}',
  },
  storage: {
    location: {
      inventory: {
        root: 'api/location/{id}/inventory',
        productId: 'api/location/{id}/product/{productId}/inventory',
      },
      export: 'api/location/export',
      root: 'api/location',
      id: 'api/location/{id}',
      fromWarehouse: 'api/warehouse/{warehouseId}/locations/{locationId}',
      storable: 'api/scan/location/{barcode}',
      pickingPath: {
        root: 'api/storage/warehouse/{warehouse}/location/pickingPath/update',
        reset: 'api/storage/warehouse/{warehouse}/location/pickingPath/reset',
        toAdd: 'api/storage/warehouse/{warehouse}/location/pickingPath/toAdd',
        list: 'api/storage/warehouse/{warehouse}/location/pickingPath/list',
        exportCSV: 'api/storage/warehouse/{warehouse}/location/pickingPath/export/csv',
      },
      barcode: 'api/scan/location/{barcode}',
      type: {
        root: 'api/storage/location/type',
        idParam: 'api/storage/location/type/{id}',
      },
      defaultProducts: 'api/storage/location/{id}/default-products',
      maximumQuantity: 'api/storage/location/{id}/maximum-quantity',
      warehouse: {
        getFallbackLocations: 'api/storage/location/warehouse/{warehouse}/fallback-locations',
        getLocationsWithoutFallback: 'api/storage/location/warehouse/{warehouse}/locations-without-fallback',
        setFallbackLocations: 'api/storage/location/warehouse/{warehouse}/massaction/fallbackLocation',
      },
      masterpallet: {
        id: 'api/storage/masterpallet/{id}',
        addMasterpallet: 'api/storage/masterpallet',
      },
      palletPath: {
        root: 'api/location/pallet-path',
        getAssignedLocations: 'api/location/pallet-path/assigned',
        getUnassignedLocations: 'api/location/pallet-path/unassigned',
        export: 'api/location/pallet-path/export/csv',
      },
      lanes: 'api/location/lanes',
      importFromCsv: 'api/location/import-csv',
      importSampleCsv: 'api/location/import-csv/sample',
    },
    warehouse: {
      root: 'api/storage/warehouse',
      idParam: 'api/storage/warehouse/{id}',
      reloadPickingPath: 'api/storage/warehouse/{warehouse}/location/pickingPath',
    },
  },
  sales: {
    order: {
      root: 'api/sales/order',
      getOrder: 'api/sales/order/{id}',
      print: 'api/sales/order/print/{id}',
      search: 'api/search/orders',
      images: {
        get: 'api/sales/order/{orderId}/images',
      },
      scanOrder: 'api/scan/order/{barcode}',
      uploadImage: 'api/sales/order/{orderId}/image',
      blockedReplenishments: 'api/sales/order/{id}/replenishments',
      availableStock: 'api/sales/order/{orderId}/available-stocks',
      status: {
        root: 'api/sales/order/status',
        postDispatched: 'api/sales/orders/dispatch',
      },
      type: 'api/sales/order/type',
      noImagesLog: 'api/sales/order/{id}/log/no-images',
      shippingInfo: 'api/sales/order/{orderId}/shipping',
      changeTrackingNumber: 'api/courier/package-order-delivery/order/{id}/change-tracking-number',
      changeShippingMethod: 'api/sales/order/{id}/change-shipping-method',
      shippingMethodList: 'api/warehouse/{warehouseId}/shipping/methods',
      documents: {
        getByOrder: 'api/order/{orderId}/documents',
        massDownload: 'api/order/documents/download-merged',
        download: 'api/order/document/{documentId}/download',
        waybillDownload: 'api/courier/package-order/{id}/waybill/download',
      },
      CSVTemplate: 'api/order/csv/sample',
      createOrderFromCSV: 'api/order/csv/create',
      autoPack: 'api/packing/{orderId}/auto-pack',
      packingPause: 'api/packing/{orderId}/pause',
      packingCancel: 'api/packing/{orderId}/reset',
      invalidAddresses: {
        unresolved: 'api/courier/address-map/unresolved',
        resolve: 'api/courier/address-map',
      },
      cancelShipment: 'api/sales/order/{orderId}/cancel-shipment',
      refreshShipmentStatus: 'api/sales/order/{orderId}/refresh-shipment',
      shipmentActions: 'api/sales/order/{orderId}/shipments/actions',
    },
  },
  series: {
    createAssignedItem: 'api/sales/order/{orderId}/expectedItem/{itemId}/assignedItem',
    updateAssignedItem: 'api/sales/order/{orderId}/expectedItem/{itemId}/assignedItem/{assignedItemId}',
    deleteAssignedItem: 'api/sales/order/{orderId}/expectedItem/{itemId}/assignedItem/{assignedItemId}',
  },
  monitoring: {
    transferLogs: 'api/monitoring/transfer-logs',
    id: 'api/monitoring/transfer-logs/{id}',
  },
  scanner: {
    location: 'api/scan/location/{barcode}',
    product: 'api/scan/product/{barcode}',
    productBySku: 'api/scan/product-sku/{barcode}',
    order: 'api/scan/order/{barcode}',
  },
  acl: {
    employee: {
      root: 'api/staff/employee/{employeeId}/groups',
      globalGroupId: 'api/staff/employee/{employeeId}/groups/global/{groupId}',
    },
    security: {
      permissions: 'api/staff/security/permissions',
      group: {
        root: 'api/staff/security/group',
        idParam: 'api/staff/security/group/{id}',
        members: 'api/staff/security/group/{id}/members',
      },
      ips: {
        root: 'api/staff/security/group/{id}/ips',
        deleteAddress: 'api/staff/security/group/{id}/ips/{rowId}',
      },
      me: 'api/staff/security/permissions',
    },
    me: 'api/me/permissions',
  },
  employee: {
    root: 'api/staff/employee',
    idParam: 'api/staff/employee/{id}',
  },
  document: {
    root: 'api/document',
    id: 'api/document/{code}/{id}',
    types: 'api/document/types',
  },
  goodsMovement: {
    root: 'api/goods-movement',
    take: 'api/goods-movement/take',
    put: 'api/goods-movement/put',
    approve: 'api/goods-movement/approve',
  },
  product: {
    maximumQuantity: {
      getListOfMaximumQuantity: 'api/pim/product/{productId}/maximum-quantity',
      putQuantityPerLocation: 'api/pim/product/{productId}/maximum-quantity/{locationId}',
    },
    assignedItems: 'api/pim/product/{productId}/assigned-items',
  },
  returns: {
    root: 'api/returns',
    batch: 'api/returns/batch',
    unaccepted: 'api/returns/unaccepted',
    accepted: 'api/returns/accepted',
    myUnaccepted: 'api/returns/my-unaccepted',
    done: 'api/returns/done',
    checkProduct: 'api/order/{orderId}/check-sent-product',
    edit: 'api/returns/{returnId}',
  },
  transferRequest: {
    root: 'api/transfer-requests',
    details: 'api/transfer-request/{id}',
    unassigned: 'api/transfer-requests/unassigned',
    assigned: 'api/transfer-requests/assigned',
    done: 'api/transfer-requests/done',
    reserved: 'api/transfer-requests/reserved',
    getToTake: 'api/transfer-requests/to-take',
    getToPut: 'api/transfer-requests/items/to-put',
    toTake: 'api/transfer-requests/{transferId}/take',
    putIt: 'api/transfer-requests/{transferId}/put',
  },
  reports: {
    get: 'api/reports',
    exportCSV: 'api/reports/export/csv',
    additionalActivity: {
      root: 'api/reports/configuration/additional-activity',
      id: 'api/reports/configuration/additional-activity/{id}',
      get: 'api/reports/additional-activities',
    },
    visualization: 'api/reports/visualization',
    temporaryActivityReport: 'api/reports/temporary-activity',
  },
  sticker: {
    root: 'api/order/{orderId}/stickers',
    actions: 'api/order/{orderId}/sticker',
  },
  handlingPallets: {
    done: 'api/forklift-operator-task/done',
    new: 'api/forklift-operator-task/new',
    cancelled: 'api/forklift-operator-task/cancelled',
    setStatusOnTheFloor: 'api/location/{locationId}/status/on-the-floor',
    setStatusAtTheTop: 'api/location/{locationId}/status/at-the-top',
  },
  package: {
    packageSize: 'api/courier/package-size',
    singlePackage: 'api/courier/package-size/{id}',
    addBoxToOrder: 'api/courier/package-order',
    setNumberOfPackageToPack: 'api/courier/package-order/batch',
    sendShip: 'api/sales/order/{orderId}/ship',
    fetchOrderWaybill: 'api/sales/order/{orderId}/refresh-shipment',
  },
  statistics: {
    general: 'api/statistics/daily-statistics',
    seriesList: 'api/inventory-stack',
    live: 'api/statistics/live-statistics',
  },
  labelPrinter: {
    root: 'api/device/label-printer',
    scheduleToPrint: 'api/waybill/schedule-to-print',
    getPrinter: 'api/scan/printer/{barcode}',
  },
  admin: {
    warehouse: {
      root: 'api/admin/warehouse/{id}',
    },
  },
  stores: {
    getStores: 'api/stores',
  },
  twoFactor: {
    authenticate: '2fa/authenticate',
    register: '2fa/register',
    reset: 'api/staff/employee/{id}/2fa/reset',
  },
  broadcast: {
    uploadInformation: 'api/debug/upload-information',
  },
  settings: {
    default: 'api/settings/default',
    warehouse: 'api/warehouse/{warehouseId}/settings',
    warehousePublic: 'api/warehouse/{warehouseId}/settings/public',
    warehouseLocations: 'api/warehouse/{warehouseId}/locations',
  },
  survey: {
    root: 'api/survey',
  },
  commentList: {
    root: 'api/comment-template/{type}',
    edit: 'api/comment-template/{type}/{id}',
  },
};
