import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EanValidatorService {
  static LONGEST_EAN_LENGTH = 14;

  static LENGTH_REGEX = '^(\\d{8}|\\d{12,14}|\\d{17,})$';

  static DIGITS_REGEX = '^(?!.*(?:-.*){3})([\\w]+(?:-(?=.*\\d)[\\w\\d]+)*|\\d+(?:-[\\w]+)*)$';

  public validate(code: string): boolean {
    if (typeof code === 'undefined') {
      return true;
    }

    if (!code.match(EanValidatorService.LENGTH_REGEX)) {
      return false;
    }

    const shortCode = this.addMissingZeros(code).slice(0, -1);
    const sum = shortCode
      .split('')
      .map((x, index) => {
        return +x * (index % 2 === 0 ? 3 : 1);
      })
      .reduce((a, b) => a + b, 0);

    return (10 - (sum % 10)) % 10 === +code[code.length - 1];
  }

  public validateSoft(code: string): boolean {
    return typeof code === 'undefined' || (code.length >= 6 && !!code.match(EanValidatorService.DIGITS_REGEX));
  }

  public addMissingZeros(code: string): string {
    const missingZeros = new Array(EanValidatorService.LONGEST_EAN_LENGTH - code.length).fill(0).join('');
    return missingZeros + code;
  }
}
