import { Injectable } from '@angular/core';
import { BarcodeInput } from './model/BarcodeInput';
import { ScanInputAction } from './model/ScanInputAction';
import { ScanSubject } from './type/ScanSubject';
import { EanValidatorService } from './eanValidator.service';
import { SkuValidatorService } from './skuValidator.service';
import { CorrelationService } from '../../core/services/correlation.service';

@Injectable({
  providedIn: 'root',
})
export class BarcodeInputService {
  inputs: BarcodeInput[] = [];

  constructor(
    public eanValidator: EanValidatorService,
    private skuValidator: SkuValidatorService,
    private correlationService: CorrelationService,
  ) {}

  detectScannedSubjectByPattern(code: string): ScanSubject {
    this.correlationService.generateId();
    if (this.eanValidator.validateSoft(code)) {
      console.log('product:', code);
      return 'product';
    }

    if (this.skuValidator.validate(code)) {
      console.log('sku:', code);
      return 'sku';
    }

    console.log('other:', code);
    return 'other';
  }

  addInput(name: string): void {
    this.inputs.push(new BarcodeInput(name));
  }

  showScanInput(name: string = 'main', focus: boolean = true): void {
    this.getContainer(name).removeAttribute('hidden');

    if (focus) {
      this.getInputNativeElement(name).focus();
    }
  }

  hideScanInput(name: string = 'main'): void {
    this.getContainer(name).setAttribute('hidden', '');
    this.clearScanInputActions();
  }

  focusScanInput(name: string = 'main'): void {
    const input = this.getInputNativeElement(name);

    if (input) {
      input.focus();
    }
  }

  setScanInputAction(action: ScanInputAction, name: string = 'main') {
    this.getInput(name).setAction(action);
  }

  getScanInputAction(name: string, subject: ScanSubject): ScanInputAction {
    return this.getInput(name).getActionBySubject(subject);
  }

  getInput(name: string) {
    return this.inputs.find(el => el.name === name);
  }

  getInputNativeElement(name: string) {
    return this.getInput(name)?.nativeElement;
  }

  clearScanInputActions() {
    this.inputs.forEach(el => (el.actions = []));
  }

  clearInputPlaceholder(name: string = 'main'): void {
    const input = this.getInputNativeElement(name);
    input.placeholder = '';
  }

  clearInput(name: string = 'main'): void {
    const input = this.getInputNativeElement(name);
    input.text = '';
  }

  setInputValue(value: string, name: string = 'main'): void {
    const input = this.getInputNativeElement(name);
    input.setAttribute('value', value);
  }

  private getContainer(name): Element {
    let result = this.getInputNativeElement(name);

    while (result.className.indexOf('barcode-input-container') === -1 && result.tagName !== 'BODY') {
      result = result.parentElement;
    }

    return result;
  }
}
